// Home.js
import React from "react";
import AboutMe from "../../sections/AboutMe";
import Partners from "../../sections/Partners";
import Stats from "../../sections/Stats";
import Proces from "../../sections/Proces";
import Reviews from "../../sections/Reviews";
import FAQ from "../../sections/FAQ";
import Consultation from "../../sections/Consultation";
import {
  about,
  partners,
  stats,
  proces,
  reviews,
  FAQ as faqData,
  consultation,
} from "../Data";

function About() {
  return (
    <>
      <AboutMe {...about} />
      <Partners h2={partners.h2} images={partners} />
      <Stats {...stats} />
      <Proces h2={proces.h2} proces={proces} />
      <Reviews h2={reviews.h2} reviews={reviews} />
      <FAQ h2={faqData.h2} faqData={faqData} />
      <Consultation {...consultation} />
    </>
  );
}

export default About;
