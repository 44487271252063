import React from "react";
import "./Normalize.css";
import "./App.css";
import Home from "./components/pages/Home";
import Footer from "./components/pages/Footer";
import About from "./components/pages/About";
import Projects from "./components/pages/Projects";
import Contact from "./components/pages/Contact";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import CaseDetails from "./components/pages/CaseDetails"; // Zorg ervoor dat het pad naar CaseDetails correct is

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/case/:caseName" element={<CaseDetails />} />{" "}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
