import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
function AboutMe({ topLine, headline, description, img }) {
  return (
    <>
      <div className="about-section">
        <div>
          <span>{topLine}</span>
          <h1>{headline}</h1>
          <p>{description}</p>
          <div className="socials">
            <Link
              className="social-icon-link"
              to="/"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </Link>
            <Link
              className="social-icon-link"
              to="/"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </Link>
            <Link
              className="social-icon-link"
              to="/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
        <div className="image" style={{ backgroundImage: `url(${img})` }}></div>
      </div>
    </>
  );
}

export default AboutMe;
