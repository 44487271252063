import React from "react";

function Home({ topLine, headline, description, img }) {
  return (
    <>
      <div className="home-section">
        <div>
          <span>{topLine}</span>
          <h1>{headline}</h1>
          <p>{description}</p>
        </div>
        <div className="image" style={{ backgroundImage: `url(${img})` }}></div>
      </div>
    </>
  );
}

export default Home;
