import React from "react";
import Welcome from "../../sections/Welcome";
import Partners from "../../sections/Partners";
import Stats from "../../sections/Stats";
import FourCases from "../../sections/FourCases";
import Reviews from "../../sections/Reviews";
import FAQ from "../../sections/FAQ";
import Consultation from "../../sections/Consultation";
import {
  welcome,
  partners,
  stats,
  projects,
  reviews,
  FAQ as faqData,
  consultation,
} from "../Data";

function Home() {
  return (
    <>
      <Welcome {...welcome} />
      <Partners h2={partners.h2} images={partners} />
      <Stats {...stats} />
      <FourCases h2={projects.h2} projects={projects} />
      <Reviews h2={reviews.h2} reviews={reviews} />
      <FAQ h2={faqData.h2} faqData={faqData} />
      <Consultation {...consultation} />
    </>
  );
}

export default Home;
