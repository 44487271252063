import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons/lib";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleClickHamburger = () => {
    setClick(!click);
    setMobileMenu(!mobileMenu);
  };

  const closeMobileMenu = () => {
    setClick(false);
    setMobileMenu(false);
    window.scrollTo(0, 0);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);

    return () => {
      window.removeEventListener("resize", showButton);
    };
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="mobileNav">
          <Link to="/">
            <div className="logo"></div>
          </Link>
          <div
            className={`hamburger ${click ? "active" : ""}`}
            onClick={handleClickHamburger}
          ></div>
          <div
            className={`close ${click ? "active" : ""}`}
            onClick={closeMobileMenu}
          ></div>
        </nav>
        {mobileMenu && (
          <div className="mobileMenu">
            <div className="links">
              <Link to="/" onClick={closeMobileMenu}>
                Home
              </Link>
              <Link to="/about" onClick={closeMobileMenu}>
                About
              </Link>
              <Link to="/projects" onClick={closeMobileMenu}>
                Projects
              </Link>
              <Link to="/contact" onClick={closeMobileMenu}>
                Contact
              </Link>
            </div>
          </div>
        )}
        <nav className="desktopTabletNav">
          <Link to="/">
            <div className="logo"></div>
          </Link>
          <div className={click ? "nav-menu active" : "nav-menu"}>
            <Link to="/" onClick={closeMobileMenu}>
              Home
            </Link>
            <Link to="/about" onClick={closeMobileMenu}>
              About
            </Link>
            <Link to="/projects" onClick={closeMobileMenu}>
              Projects
            </Link>
            <Link to="/contact" onClick={closeMobileMenu}>
              Contact
            </Link>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
